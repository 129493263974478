/* eslint-disable no-restricted-imports */
import fotoPerfil from '../utils/perfil.png';
import Toggle from './toggle';

const Foto = () => (
  <div className='perfil'>
    <div>
      <Toggle />
    </div>
    <img className='foto' src={fotoPerfil} alt='foto del perfil' width={200} />
  </div>
);

export default Foto;
