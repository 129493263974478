/* eslint-disable no-restricted-imports */
// import logo from './logo.svg';
import './styles/App.css';
import Index from './pages';

function App() {
  return (
    <div>
      <Index />
    </div>
  );
}

export default App;
