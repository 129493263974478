import { useContextProvider } from '../context/contextProvider';
import { AnimatedHover, AnimatedForm } from './animatedHover';

function Nombre(props) {
  const { nombre, cargo, descarga } = props;
  const { traslate } = useContextProvider();

  return (
    <div className='w-full grid lg:h-p lg:bg-gray-600 '>
      <div className='child-container-nombre'>
        <AnimatedHover>
          <h1 className='font-color'>{nombre}</h1>
        </AnimatedHover>
        <div className='w-4/5 h-2 bg-amber-200' />
        <AnimatedHover>
          <h2 className='font-color'>{cargo}</h2>
        </AnimatedHover>
        <a
          href={
            traslate
              ? 'https://drive.google.com/file/d/1r5A8NWDbMQUPKLu6PlhZLkSEwycL8gwP/view?usp=sharing'
              : 'https://drive.google.com/file/d/1ZtuYjswWDPrJLVr1beMLnY-92Q2MHX1q/view?usp=sharing'
          }
          download='cv-jpav.pdf'
          target='_blank'
          rel='noreferrer noopener'
          className='bg-amber-200 hover:bg-amber-300 text-black font-bold py-2 px-4 rounded-full'
        >
          {descarga}
        </a>
        <AnimatedForm />
      </div>
    </div>
  );
}

export default Nombre;
