import React from 'react';

const InformacionLG = ({
  titulo1 = null,
  valor1 = null,
  titulo2 = null,
  valor2 = null,
  titulo3 = null,
  valor3 = null,
  titulo4 = null,
  valor4 = null,
  name = null,
  tel = null,
  gitUser,
  valor5 = null,
  valor6 = null,
  valor7 = null,
  titulo8 = null,
  valor8 = null,
  titulo9 = null,
  valor9 = null,
  link = null,
  valor10 = null,
}) => (
  <div>
    <div className='w-4/5 md:w-full lg:w-4/5 mx-auto'>
      <p className='personal-info-tituloLG'>{titulo9}</p>
      <p className='personal-info-valorLG'>
        <a target='_blank' href={link} rel='noreferrer noopener'>
          {valor10}
        </a>
      </p>
      <p className='personal-info-valorLG'>{valor9}</p>
      <p className='personal-info-tituloLG'>{titulo1}</p>
      <p className='personal-info-valorLG'>{valor1}</p>
      <p className=' personal-info-tituloLG'>{titulo2}</p>
      <p className='personal-info-valorLG'>
        <a target='_blank' href={valor2} rel='noreferrer noopener'>
          {name}{' '}
        </a>
      </p>
      <p className='personal-info-valorLG'>{valor5}</p>
      <p className=' personal-info-tituloLG'>{titulo8}</p>
      <p className='personal-info-valorLG'>{valor8}</p>
      <p className='personal-info-tituloLG'>{titulo3}</p>
      <p className='personal-info-valorLG'>
        <a target='_blank' href={valor3} rel='noreferrer noopener'>
          {gitUser}
        </a>
      </p>

      <p className='personal-info-tituloLG'>{titulo4}</p>
      <p className='personal-info-valorLG'>
        <a target='_blank' href={valor4} rel='noreferrer noopener'>
          {tel}{' '}
        </a>
      </p>

      <p className='personal-info-valorLG'>{valor6}</p>
      <p className='personal-info-valorLG'>{valor7}</p>
    </div>
  </div>
);

export default InformacionLG;
