import emailjs from '@emailjs/browser';
import Swal from 'sweetalert2';

export const sendInfo = async (data) => {
  await emailjs
    .send(
      `${process.env.REACT_APP_SERVICE_ID}`,
      `${process.env.REACT_APP_TEMPLATE_ID}`,
      data,
      `${process.env.REACT_APP_PUBLIC_KEY}`
    )
    .then(
      () => {
        Swal.fire({
          icon: 'success',
          title: `${data.nombre} ${data.swal1}`,
          showConfirmButton: false,
          color: '#716add',
          timer: 2000,
        }).then(() => true);
      },
      (error) => {
        console.log(error.text);
      }
    );
};

export const contactWs = () => {
  const number = `${'https://wa.me/573113378220?text=Hola,%20Juan%20Pablo,%20estuvimos%20revisando%20su%20hoja%20de%20vida%20y%20quisiéramos%20conocer%20más%20sobre%20su%20perfil,%20gracias.'}`;
  window.open(number, '_blank');
};
