import { motion } from 'framer-motion';

export const AnimatedHover = ({ children }) => (
  <motion.div
    whileHover={{ scale: 1.1 }}
    whileTap={{ scale: 0.6 }}
    transition={{ duration: 0.7, ease: [0.6, 0.05, -0.01, 0.9] }}
  >
    {children}
  </motion.div>
);

// onclick animation
export const OnClickAnimation = ({ children }) => (
  <motion.div whileTap={{ scale: 0.9 }}>{children}</motion.div>
);

// form animation

const draw = {
  hidden: { pathLength: 0, opacity: 0 },
  visible: (i) => {
    const delay = 1 + i * 0.5;
    return {
      pathLength: 1,
      opacity: 1,
      transition: {
        pathLength: { delay, type: 'spring', duration: 1.5, bounce: 0 },
        opacity: { delay, duration: 0.01 },
      },
    };
  },
};

export const AnimatedForm = () => (
  <motion.svg
    width='300'
    height='100'
    viewBox='220 -100 100 400'
    initial='hidden'
    animate='visible'
  >
    <motion.circle
      cx='100'
      cy='100'
      r='80'
      stroke='#ff0055'
      variants={draw}
      custom={1}
    />
    <motion.line
      x1='220'
      y1='30'
      x2='360'
      y2='170'
      stroke='#00cc88'
      variants={draw}
      custom={2}
    />
    <motion.line
      x1='220'
      y1='170'
      x2='360'
      y2='30'
      stroke='#00cc88'
      variants={draw}
      custom={2.5}
    />
    <motion.rect
      width='140'
      height='140'
      x='410'
      y='30'
      rx='20'
      stroke='#0099ff'
      variants={draw}
      custom={3}
    />
  </motion.svg>
);
