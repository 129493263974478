import React from 'react';

const WsIcon = ({ contactar, contact }) => (
  <div
    className='tooltip'
    tabIndex={0}
    aria-label='whatsapp'
    role='button'
    onClick={contactar}
    onKeyDown={contactar}
    id='whatsapp'
  >
    <span className='tooltiptext'>{contact}</span>
  </div>
);

export default WsIcon;
