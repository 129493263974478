import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faGithub, faLinkedin } from '@fortawesome/free-brands-svg-icons';
import { faEnvelope } from '@fortawesome/free-regular-svg-icons';
import { faPhone } from '@fortawesome/free-solid-svg-icons';

const Informacion = ({
  email = null,
  emailValue = null,
  linkedin = null,
  urlLinkedin = null,
  name = null,
  github = null,
  urlGithub = null,
  telefono = null,
  telValue = null,
  tel = null,
  gitUser,
  valor5 = '',
  valor6 = null,
  valor7 = null,
  bool = '',
}) => (
  <div>
    <div className='w-4/5 mx-auto'>
      <p className='personal-info-titulo'>{email}</p>
      <p className='personal-info-valor hover:text-gray-600 md:hover:text-white lg:hover:text-gray-600 w-fit '>
        <a href='mailto:juan1037av@gmail.com'>
          {bool !== '' ? <FontAwesomeIcon icon={faEnvelope} /> : null}{' '}
          {emailValue}
        </a>
      </p>
      <p className='personal-info-valor'>{valor6}</p>
      <p className=' personal-info-titulo'>{linkedin}</p>
      <p className='personal-info-valor w-fit hover:text-gray-600 md:hover:text-white lg:hover:text-gray-600'>
        <a target='_blank' href={urlLinkedin} rel='noreferrer noopener'>
          {bool !== '' ? <FontAwesomeIcon icon={faLinkedin} /> : null} {name}
        </a>
      </p>
      <p className='personal-info-titulo'>{github}</p>
      <p className='personal-info-valor w-fit hover:text-gray-600 md:hover:text-white lg:hover:text-gray-600'>
        <a target='_blank' href={urlGithub} rel='noreferrer noopener'>
          {bool !== '' ? <FontAwesomeIcon icon={faGithub} /> : null} {gitUser}
        </a>
      </p>
      <p className='personal-info-titulo'>{telefono}</p>
      <p className='personal-info-valor w-fit hover:text-gray-600 md:hover:text-white lg:hover:text-gray-600'>
        <a target='_blank' href={telValue} rel='noreferrer noopener'>
          {bool !== '' ? <FontAwesomeIcon icon={faPhone} /> : null} {tel}
        </a>
      </p>

      <p>{valor5}</p>
      <p className='personal-info-valor'>{valor7}</p>
    </div>
  </div>
);

export default Informacion;
