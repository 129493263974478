/* eslint-disable jsx-a11y/no-static-element-interactions */
import { useEffect, useState } from 'react';

const Foto = (props) => {
  const [dropDown, setDropDown] = useState(true);

  useEffect(() => {}, [dropDown]);

  const { titulo } = props;
  return (
    // eslint-disable-next-line jsx-a11y/no-static-element-interactions
    // eslint-disable-next-line jsx-a11y/click-events-have-key-events
    <div className='container-separador' onClick={() => setDropDown(!dropDown)}>
      <p className='my-6 py-1 font-bold text-xl'>{titulo}</p>
    </div>
  );
};

export default Foto;
