/* eslint-disable no-restricted-imports */
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useContextProvider } from '../context/contextProvider';
import i18n from '../i18n';
import Foto from '../components/foto';
import Herramientas from '../components/herramientas';
import Informacion from '../components/informacion';
import InformacionLG from '../components/InformacionLG';
import Nombre from '../components/nombre';
import Contacto from '../components/contacto';
import Separador from '../components/separador';
import { OnClickAnimation } from '../components/animatedHover';
import certificate from '../assets/imgs/certificate.png';
import WsIcon from '../components/wsIcon';
import { contactWs } from '../services/ContactServices';

const Index = () => {
  const { traslate } = useContextProvider();
  const { t } = useTranslation();
  useEffect(() => {
    if (traslate) {
      i18n.changeLanguage('en');
    } else {
      i18n.changeLanguage('es');
    }
  }, [traslate]);

  return (
    <div>
      <div>
        <div className='grid md:grid-cols-2 '>
          <Foto />

          <Nombre
            nombre='Juan Pablo Arenas Velez'
            cargo='FullStack JS'
            descarga={t('cv')}
          />
        </div>

        <div className='grid md:grid-cols-2'>
          <div className='md:bg-gray-600  lg:bg-white lg:ml-4'>
            <details open>
              <summary className='list-none'>
                <OnClickAnimation>
                  <Separador titulo={t('informacionPersonal')} />
                </OnClickAnimation>
              </summary>
              <Informacion
                email={t('correo')}
                emailValue='juan1037av@gmail.com'
                linkedin='Linkedin: '
                urlLinkedin='https://www.linkedin.com/in/juandev47/'
                github='GitHub: '
                gitUser='@juanDev-47'
                urlGithub='https://github.com/juanDev-47'
                telefono={t('telefono')}
                telValue='tel:+573113378220'
                name='Juan Pablo Arenas Vélez'
                tel='311 337 8220'
                bool='true'
              />
            </details>

            <details open>
              <summary className='list-none'>
                <OnClickAnimation>
                  <Separador titulo={t('perfilLabel')} />
                </OnClickAnimation>
              </summary>

              <div className='w-full'>
                <Informacion valor5={t('perfil')} />
              </div>
            </details>

            <details open>
              <summary className='list-none'>
                <OnClickAnimation>
                  <Separador titulo={t('herramientas')} />
                </OnClickAnimation>
              </summary>
              <div className='w-full'>
                <Herramientas />
              </div>
            </details>

            <details open>
              <summary className='list-none'>
                <OnClickAnimation>
                  <Separador titulo={t('academicoLabel')} />
                </OnClickAnimation>
              </summary>

              <div className='w-full'>
                <Informacion
                  email='I.E. José Antonio Galán'
                  valor6={t('bachiller')}
                  linkedin='Universidad de Antioquia'
                  valor5={t('universidad')}
                />
              </div>
            </details>
          </div>

          <div className='md:w-4/5 lg:w-full md:mx-auto'>
            <details open>
              <summary className='list-none'>
                <OnClickAnimation>
                  <Separador titulo={t('infoLaboralLabel')} />
                </OnClickAnimation>
              </summary>
              <div className='w-full'>
                <InformacionLG
                  titulo1='Innovati S.A.S'
                  valor1={t('infoLaboral3')}
                  titulo8='Copaques S.A.S'
                  valor8={t('infoLaboral1')}
                  titulo2='SyG'
                  valor5={t('infoLaboral2')}
                />
              </div>
            </details>

            <details open>
              <summary className='list-none'>
                <OnClickAnimation>
                  <Separador titulo={t('idiomasLabel')} />
                </OnClickAnimation>
              </summary>

              <div className='w-full'>
                <InformacionLG titulo1={t('ingles')} titulo2={t('spanish')} />
              </div>
            </details>

            <details open>
              <summary className='list-none'>
                <OnClickAnimation>
                  <Separador titulo={t('proyectosLabel')} />
                </OnClickAnimation>
              </summary>
              <div className='w-full'>
                <InformacionLG
                  titulo9='OldWave e-commerce'
                  valor9={t('proyecto1')}
                  titulo1='CMR Comercial:'
                  valor5={t('proyecto2')}
                  titulo3='BlackBox:'
                  valor6={t('proyecto3')}
                />
              </div>
            </details>

            <details open>
              <summary className='list-none'>
                <OnClickAnimation>
                  <Separador titulo={t('cursosLabel')} />
                </OnClickAnimation>
              </summary>
              <div className='w-full'>
                <InformacionLG
                  titulo1={t('repositorioLabel')}
                  valor2='https://github.com/juanDev-47/about-me'
                  name={t('repositorio')}
                />
              </div>
              <div className='w-full'>
                <a href='https://www.credly.com/badges/063986fd-fe25-4b99-8df2-8cccee6a2a92'>
                  <img
                    className='mx-auto'
                    src={certificate}
                    alt='certificate'
                    width={200}
                    height={200}
                  />
                </a>
              </div>
            </details>
          </div>
        </div>
        {/* Here is out of the principal content */}
        <Contacto />
      </div>
      <WsIcon contactar={contactWs} contact={t('contactame')} />
    </div>
  );
};

export default Index;
