import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useContextProvider } from '../context/contextProvider';
import i18n from '../i18n';
import { sendInfo } from '../services/ContactServices';

const Contacto = () => {
  const { traslate } = useContextProvider();
  const { t } = useTranslation();
  useEffect(() => {
    if (traslate) {
      i18n.changeLanguage('en');
    } else {
      i18n.changeLanguage('es');
    }
  }, [traslate]);

  const [nombre, setNombre] = useState('');
  const [email, setEmail] = useState('');
  const [contacto, setContacto] = useState('');
  const [intencion, setIntencion] = useState('');
  const [mensaje, setMensaje] = useState('');

  const handleSubmit = async (e) => {
    e.preventDefault();
    const data = {
      nombre,
      email,
      contacto,
      intencion,
      mensaje,
      swal1: t('Swal1'),
    };
    // execute function and wait for the response and arrow function
    await sendInfo(data).then((res) => {
      if (res === undefined) {
        setNombre('');
        setEmail('');
        setContacto('');
        setIntencion('');
        setMensaje('');
      }
    });
  };

  return (
    <div className='min-h-screen flex flex-col justify-center py-12 px-6 lg:px-8'>
      <div className='sm:mx-auto py-6 bg-gray-200 sm:w-full sm:max-w-md rounded-t-lg'>
        <img
          className='mx-auto h-12 w-auto'
          src='https://tailwindui.com/img/logos/workflow-mark-indigo-600.svg'
          alt='Workflow'
        />
        <h2 className='mt-6 text-center text-3xl font-extrabold text-gray-900'>
          {t('inputTitulo')}
        </h2>
      </div>

      <div className=' bg-gray-200 sm:mx-auto sm:w-full sm:max-w-md'>
        <div className='py-8 px-6 shadow sm:px-10'>
          <form className='mb-0 space-y-6' onSubmit={handleSubmit}>
            <div>
              <label
                htmlFor='nombre'
                className='block text-sm font-medium text-gray-700'
              >
                {t('inputNombre')}
              </label>
              <div className='mt-1'>
                <input
                  onChange={(e) => {
                    setNombre(e.target.value);
                  }}
                  value={nombre}
                  id='nombre'
                  name='nombre'
                  type='text'
                  required
                  className='sm:mx-auto px-2 w-full rounded-lg'
                />
              </div>
            </div>

            <div>
              <label
                htmlFor='email'
                className='block text-sm font-medium text-gray-700'
              >
                {t('inputEmail')}
              </label>
              <div className='mt-1'>
                <input
                  onChange={(e) => {
                    setEmail(e.target.value);
                  }}
                  value={email}
                  id='email'
                  name='email'
                  type='email'
                  required
                  className='sm:mx-auto px-2 w-full rounded-lg'
                />
              </div>
            </div>

            <div>
              <label
                htmlFor='contacto'
                className='block text-sm font-medium text-gray-700'
              >
                {t('inputContacto')}
              </label>
              <div className='mt-1'>
                <input
                  onChange={(e) => {
                    setContacto(e.target.value);
                  }}
                  value={contacto}
                  id='contacto'
                  name='contacto'
                  type='tel'
                  required
                  className='sm:mx-auto px-2 w-full rounded-lg'
                />
              </div>
            </div>

            <div>
              <label
                htmlFor='company-size'
                className='block text-sm font-medium text-gray-700'
              >
                {t('inputIntencion')}
              </label>
              <div className='mt-1'>
                <select
                  onChange={(e) => {
                    setIntencion(e.target.value);
                  }}
                  value={intencion}
                  name='company-size'
                  id='company-size'
                  className=''
                >
                  <option value=''>{t('inputMensaje')}</option>
                  <option value='conocernos'>{t('inputOpcion1')}</option>
                  <option value='trabajo'>{t('inputOpcion2')}</option>
                  <option value='colaborar'>{t('inputOpcion3')}</option>
                </select>
              </div>
            </div>

            <div>
              <label
                htmlFor='mensaje'
                className='block text-sm font-medium text-gray-700'
              >
                {t('inputMensaje2')}
              </label>
              <div className='mt-1'>
                <textarea
                  onChange={(e) => {
                    setMensaje(e.target.value);
                  }}
                  value={mensaje}
                  id='mensaje'
                  name='mensaje'
                  required
                  className='sm:mx-auto px-2 w-full rounded-lg'
                />
              </div>
            </div>

            <div>
              <button
                type='submit'
                className='w-full flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500'
              >
                {t('inputEnviar')}
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default Contacto;
