import React from 'react';

const Herramientas = () => (
  <div>
    <div className='w-4/5 py-2 mx-auto grid grid-cols-3 gap-3 md:grid-cols-4 justify-items-center'>
      <div>
        <a
          href='https://developer.mozilla.org/en-US/docs/Web/JavaScript'
          target='_blank'
          rel='noreferrer'
        >
          <img
            src='https://raw.githubusercontent.com/devicons/devicon/master/icons/javascript/javascript-original.svg'
            alt='javascript'
            width='40'
            height='40'
          />
        </a>
      </div>
      <div>
        <a href='https://angular.io' target='_blank' rel='noreferrer'>
          <img
            src='https://angular.io/assets/images/logos/angular/angular.svg'
            alt='angular'
            width='40'
            height='40'
          />
        </a>
      </div>

      <div>
        <a
          href='https://www.typescriptlang.org/'
          target='_blank'
          rel='noreferrer'
        >
          <img
            src='https://raw.githubusercontent.com/devicons/devicon/master/icons/typescript/typescript-original.svg'
            alt='typescript'
            width='40'
            height='40'
          />
        </a>
      </div>
      <div>
        <a href='https://reactjs.org/' target='_blank' rel='noreferrer'>
          <img
            src='https://raw.githubusercontent.com/devicons/devicon/master/icons/react/react-original-wordmark.svg'
            alt='react'
            width='40'
            height='40'
          />
        </a>
      </div>
      <div>
        <a
          href='https://azure.microsoft.com/en-in/'
          target='_blank'
          rel='noreferrer'
        >
          <img
            src='https://www.vectorlogo.zone/logos/microsoft_azure/microsoft_azure-icon.svg'
            alt='azure'
            width='40'
            height='40'
          />
        </a>
      </div>
      <div>
        <a href='https://nodejs.org' target='_blank' rel='noreferrer'>
          <img
            src='https://raw.githubusercontent.com/devicons/devicon/master/icons/nodejs/nodejs-original-wordmark.svg'
            alt='nodejs'
            width='40'
            height='40'
          />
        </a>
      </div>
      <div>
        <a href='https://nestjs.com/' target='_blank' rel='noreferrer'>
          <img
            src='https://raw.githubusercontent.com/devicons/devicon/master/icons/nestjs/nestjs-plain.svg'
            alt='nestjs'
            width='40'
            height='40'
          />
        </a>
      </div>
      <div>
        <a href='https://tailwindcss.com/' target='_blank' rel='noreferrer'>
          <img
            src='https://www.vectorlogo.zone/logos/tailwindcss/tailwindcss-icon.svg'
            alt='tailwind'
            width='40'
            height='40'
          />
        </a>
      </div>
      <div>
        <a href='https://getbootstrap.com' target='_blank' rel='noreferrer'>
          <img
            src='https://raw.githubusercontent.com/devicons/devicon/master/icons/bootstrap/bootstrap-plain-wordmark.svg'
            alt='bootstrap'
            width='40'
            height='40'
          />
        </a>
      </div>
      <div>
        <a href='https://www.chartjs.org' target='_blank' rel='noreferrer'>
          <img
            src='https://www.chartjs.org/media/logo-title.svg'
            alt='chartjs'
            width='40'
            height='40'
          />
        </a>
      </div>
      <div>
        <a
          href='https://www.w3schools.com/cs/'
          target='_blank'
          rel='noreferrer'
        >
          <img
            src='https://raw.githubusercontent.com/devicons/devicon/master/icons/csharp/csharp-original.svg'
            alt='csharp'
            width='40'
            height='40'
          />
        </a>
      </div>
      <div>
        <a
          href='https://www.w3schools.com/css/'
          target='_blank'
          rel='noreferrer'
        >
          <img
            src='https://raw.githubusercontent.com/devicons/devicon/master/icons/css3/css3-original-wordmark.svg'
            alt='css3'
            width='40'
            height='40'
          />
        </a>
      </div>
      <div>
        <a href='https://www.docker.com/' target='_blank' rel='noreferrer'>
          <img
            src='https://raw.githubusercontent.com/devicons/devicon/master/icons/docker/docker-original-wordmark.svg'
            alt='docker'
            width='40'
            height='40'
          />
        </a>
      </div>
      <div>
        <a
          href='https://dotnet.microsoft.com/'
          target='_blank'
          rel='noreferrer'
        >
          <img
            src='https://raw.githubusercontent.com/devicons/devicon/master/icons/dot-net/dot-net-original-wordmark.svg'
            alt='dotnet'
            width='40'
            height='40'
          />
        </a>
      </div>
      <div>
        <a href='https://expressjs.com' target='_blank' rel='noreferrer'>
          <img
            src='https://raw.githubusercontent.com/devicons/devicon/master/icons/express/express-original-wordmark.svg'
            alt='express'
            width='40'
            height='40'
          />
        </a>
      </div>
      <div>
        <a href='https://www.figma.com/' target='_blank' rel='noreferrer'>
          <img
            src='https://www.vectorlogo.zone/logos/figma/figma-icon.svg'
            alt='figma'
            width='40'
            height='40'
          />
        </a>
      </div>
      <div>
        <a href='https://firebase.google.com/' target='_blank' rel='noreferrer'>
          <img
            src='https://www.vectorlogo.zone/logos/firebase/firebase-icon.svg'
            alt='firebase'
            width='40'
            height='40'
          />
        </a>
      </div>
      <div>
        <a href='https://git-scm.com/' target='_blank' rel='noreferrer'>
          <img
            src='https://www.vectorlogo.zone/logos/git-scm/git-scm-icon.svg'
            alt='git'
            width='40'
            height='40'
          />
        </a>
      </div>
      <div>
        <a href='https://heroku.com' target='_blank' rel='noreferrer'>
          <img
            src='https://www.vectorlogo.zone/logos/heroku/heroku-icon.svg'
            alt='heroku'
            width='40'
            height='40'
          />
        </a>
      </div>
      <div>
        <a href='https://www.w3.org/html/' target='_blank' rel='noreferrer'>
          <img
            src='https://raw.githubusercontent.com/devicons/devicon/master/icons/html5/html5-original-wordmark.svg'
            alt='html5'
            width='40'
            height='40'
          />
        </a>
      </div>
      <div>
        <a
          href='https://www.adobe.com/in/products/illustrator.html'
          target='_blank'
          rel='noreferrer'
        >
          <img
            src='https://www.vectorlogo.zone/logos/adobe_illustrator/adobe_illustrator-icon.svg'
            alt='illustrator'
            width='40'
            height='40'
          />
        </a>
      </div>
      <div>
        <a href='https://jasmine.github.io/' target='_blank' rel='noreferrer'>
          <img
            src='https://www.vectorlogo.zone/logos/jasmine/jasmine-icon.svg'
            alt='jasmine'
            width='40'
            height='40'
          />
        </a>
      </div>
      <div>
        <a href='https://www.java.com' target='_blank' rel='noreferrer'>
          <img
            src='https://raw.githubusercontent.com/devicons/devicon/master/icons/java/java-original.svg'
            alt='java'
            width='40'
            height='40'
          />
        </a>
      </div>
      <div>
        <a href='https://jestjs.io' target='_blank' rel='noreferrer'>
          <img
            src='https://www.vectorlogo.zone/logos/jestjsio/jestjsio-icon.svg'
            alt='jest'
            width='40'
            height='40'
          />
        </a>
      </div>
      <div>
        <a
          href='https://karma-runner.github.io/latest/index.html'
          target='_blank'
          rel='noreferrer'
        >
          <img
            src='https://raw.githubusercontent.com/detain/svg-logos/780f25886640cef088af994181646db2f6b1a3f8/svg/karma.svg'
            alt='karma'
            width='40'
            height='40'
          />
        </a>
      </div>
      <div>
        <a href='https://www.linux.org/' target='_blank' rel='noreferrer'>
          <img
            src='https://raw.githubusercontent.com/devicons/devicon/master/icons/linux/linux-original.svg'
            alt='linux'
            width='40'
            height='40'
          />
        </a>
      </div>
      <div>
        <a href='https://www.mongodb.com/' target='_blank' rel='noreferrer'>
          <img
            src='https://raw.githubusercontent.com/devicons/devicon/master/icons/mongodb/mongodb-original-wordmark.svg'
            alt='mongodb'
            width='40'
            height='40'
          />
        </a>
      </div>
      <div>
        <a href='https://www.mysql.com/' target='_blank' rel='noreferrer'>
          <img
            src='https://raw.githubusercontent.com/devicons/devicon/master/icons/mysql/mysql-original-wordmark.svg'
            alt='mysql'
            width='40'
            height='40'
          />
        </a>
      </div>
      <div>
        <a href='https://nextjs.org/' target='_blank' rel='noreferrer'>
          <img
            src='https://cdn.worldvectorlogo.com/logos/nextjs-2.svg'
            alt='nextjs'
            width='40'
            height='40'
          />
        </a>
      </div>
      <div>
        <a href='https://www.postgresql.org' target='_blank' rel='noreferrer'>
          <img
            src='https://raw.githubusercontent.com/devicons/devicon/master/icons/postgresql/postgresql-original-wordmark.svg'
            alt='postgresql'
            width='40'
            height='40'
          />
        </a>
      </div>
      <div>
        <a href='https://postman.com' target='_blank' rel='noreferrer'>
          <img
            src='https://www.vectorlogo.zone/logos/getpostman/getpostman-icon.svg'
            alt='postman'
            width='40'
            height='40'
          />
        </a>
      </div>
      <div>
        <a href='https://www.python.org' target='_blank' rel='noreferrer'>
          <img
            src='https://raw.githubusercontent.com/devicons/devicon/master/icons/python/python-original.svg'
            alt='python'
            width='40'
            height='40'
          />
        </a>
      </div>
    </div>
  </div>
);

export default Herramientas;
